import { format } from "date-fns";

export const isNullOrUndefined = (value: any): boolean => {
  return value === null || value === undefined;
};

export const getFirstInitial = (name: string): string => {
  return name.trim()[0].toUpperCase();
};

export const transformToSentenceCase = (input: string) => {
  const result = input
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());

  return result;
};

export const camelCaseToSentenceCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, " $1").toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const convertUnderscoreToSentenceCase = (str = "") => {
  const words = str.toLowerCase().split("_");

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return capitalizedWords.join(" ");
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatAddress = (address: any) => {
  if (!address) return "";
  return `${address?.addressLine1}, ${address?.city}, ${address?.state} ${address?.postalCode}`;
};

export const formatPhoneNumber = (phone: string) => {
  if (!phone) return "";

  const cleaned = phone.replace(/\D/g, "");

  if (cleaned.length > 10) {
    const countryCodeLength = cleaned.length - 10;
    return `+${cleaned.slice(0, countryCodeLength)} (${cleaned.slice(
      countryCodeLength,
      countryCodeLength + 3
    )}) ${cleaned.slice(
      countryCodeLength + 3,
      countryCodeLength + 6
    )}-${cleaned.slice(countryCodeLength + 6)}`;
  }

  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
};

export const formatDateTime = (date: string | Date) => {
  return format(date, "MM/dd/yyyy 'at' h:mmaaa");
};
