import { Auth } from "@/hooks/auth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  HomeIcon,
  UsersIcon,
  BriefcaseIcon,
  HomeModernIcon,
  BoltIcon,
  DocumentIcon,
  CalendarDaysIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserIcon,
  Cog8ToothIcon,
  ShieldCheckIcon,
  LightBulbIcon,
  ArrowRightStartOnRectangleIcon,
  MagnifyingGlassIcon,
  InboxIcon,
} from "@heroicons/react/24/solid";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@/components/catalyst/dropdown";
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "@/components/catalyst/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from "@/components/catalyst/sidebar";
import { SidebarLayout } from "@/components/catalyst/sidebar-layout";
import { Avatar } from "@/components/catalyst/avatar";
import { useAppDispatch } from "@/hooks/redux";
import { useEffect, useState } from "react";
import { resetAuthUiState } from "@/slices/auth/authUi";
import { supabase } from "@/utilities/supabase";
import { setActiveTeam } from "@/slices/team/teamUi";
import { useGetCompaniesQuery } from "@/slices/company/companyApi";
import { useGetUserByIdQuery } from "@/slices/users/usersApi";
import { Skeleton } from "@/components/Skeleton";

const UserProfile = ({ user }: any) => (
  <span className="flex min-w-0 items-center gap-3">
    <Avatar
      src={user?.person?.avatarRef || undefined}
      square
      initials={
        !user?.person?.avatarRef
          ? user?.person?.name
              ?.split(" ")
              .slice(0, 2)
              .map((n: any) => n[0])
              .join("")
          : undefined
      }
      className={`size-10 ${
        !user?.person?.avatarRef ? "bg-neutral-200 text-gray-500" : ""
      }`}
      alt={user?.person?.name || user?.email}
    />
    <span className="min-w-0 max-lg:hidden">
      <span className="block truncate text-sm font-medium text-zinc-950 dark:text-white">
        {user?.person?.name || "User"}
      </span>
      <span className="block truncate text-xs font-normal text-zinc-500 dark:text-zinc-400">
        {user?.email}
      </span>
    </span>
  </span>
);

export const PlatformWithSidebar = () => {
  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [team, setTeam]: any = useState(null);
  const [userId, setUserId]: any = useState(null);
  const [currentPath, setCurrentPath] = useState("");
  const location = useLocation();

  /* API */
  const {
    data: userRes,
    isFetching: userResFetching,
    error: userResError,
  } = useGetUserByIdQuery(userId, {
    skip: !userId?.length,
  });

  const { data: companyRes, isFetching: isCompanyResFetching } =
    useGetCompaniesQuery({});

  useEffect(() => {
    if (companyRes?.data?.[0]?.id) {
      const activeTeam = companyRes.data[0];
      dispatch(setActiveTeam(activeTeam));
      setTeam(activeTeam);
    }
  }, [companyRes, dispatch]);

  useEffect(() => {
    const getUser = async () => {
      const { data } = await supabase.auth.getSession();
      setUserId(data?.session?.user?.id || "");
    };
    getUser();
  }, []);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location?.pathname]);

  const logout = async () => {
    await supabase.auth.signOut();
    navigate("/login");
    dispatch(resetAuthUiState());
  };

  const createNavItem = (route: string) => {
    return {
      path: route,
      onClick: (e: any) => {
        e.preventDefault();
        navigate(route);
      },
    };
  };

  const menuItems = [
    {
      name: "Dashboard",
      icon: HomeIcon,
      ...createNavItem("/"),
    },
    {
      name: "Relationships",
      icon: UsersIcon,
      ...createNavItem("/relationships"),
    },
    {
      name: "Deals",
      icon: BriefcaseIcon,
      ...createNavItem("/deals"),
    },
    {
      name: "Properties",
      icon: HomeModernIcon,
      ...createNavItem("/properties"),
    },
    { name: "Workflows", icon: BoltIcon, ...createNavItem("/workflows") },
    {
      name: "Documents",
      icon: DocumentIcon,
      ...createNavItem("/documents"),
    },
    {
      name: "Schedule",
      icon: CalendarDaysIcon,
      ...createNavItem("/schedule"),
    },
    {
      name: "Settings",
      icon: Cog6ToothIcon,
      bottom: true,
      ...createNavItem("/settings/general"),
    },
    {
      name: "Help Center",
      icon: QuestionMarkCircleIcon,
      href: "https://www.repsity.com/help-center",
      bottom: true,
    },
  ];

  return (
    <Auth>
      <SidebarLayout
        navbar={
          <Navbar>
            <NavbarSpacer />
            <NavbarSection>
              <NavbarItem href="/search" aria-label="Search">
                <MagnifyingGlassIcon />
              </NavbarItem>
              <NavbarItem href="/notifications" aria-label="Inbox">
                <InboxIcon />
              </NavbarItem>
              <Dropdown>
                <DropdownButton as={NavbarItem}>
                  {userResFetching ? (
                    <Skeleton className="h-2 w-full rounded-full" />
                  ) : (
                    <UserProfile user={userRes} />
                  )}
                </DropdownButton>
                <DropdownMenu className="min-w-64" anchor="bottom end">
                  <DropdownItem href="/settings">
                    <Cog8ToothIcon />
                    <DropdownLabel>Settings</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem href="https://www.repsity.com/privacy-policy">
                    <ShieldCheckIcon />
                    <DropdownLabel>Privacy policy</DropdownLabel>
                  </DropdownItem>
                  <DropdownItem href="https://www.repsity.com/share-feedback">
                    <LightBulbIcon />
                    <DropdownLabel>Share feedback</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem onClick={logout}>
                    <ArrowRightStartOnRectangleIcon />
                    <DropdownLabel>Sign out</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavbarSection>
          </Navbar>
        }
        sidebar={
          <Sidebar>
            <SidebarHeader>
              <Dropdown>
                <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                  {isCompanyResFetching ? (
                    <Skeleton className="h-2 w-full rounded-full" />
                  ) : (
                    <Avatar
                      src={team?.logo || undefined}
                      initials={
                        !team?.logo
                          ? team?.name
                              ?.split(" ")
                              .slice(0, 2)
                              .map((n: any) => n[0])
                              .join("")
                          : undefined
                      }
                      className={`size-10 square ${
                        !team?.logo ? "bg-emerald-600 text-white" : ""
                      }`}
                      alt={team?.name || "Team"}
                    />
                  )}
                  {!isCompanyResFetching && (
                    <SidebarLabel>{team?.name || "Personal Team"}</SidebarLabel>
                  )}
                  <ChevronDownIcon />
                </DropdownButton>
                <DropdownMenu
                  className="min-w-80 lg:min-w-64"
                  anchor="bottom start"
                >
                  <DropdownItem href="/settings/team">
                    <Cog8ToothIcon />
                    <SidebarLabel>Settings</SidebarLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem onClick={logout}>
                    <ArrowRightStartOnRectangleIcon />
                    <SidebarLabel>Logout</SidebarLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <SidebarSection className="max-lg:hidden">
                <SidebarItem href="/search">
                  <MagnifyingGlassIcon />
                  <SidebarLabel>Search</SidebarLabel>
                </SidebarItem>
                <SidebarItem href="/notifications">
                  <InboxIcon />
                  <SidebarLabel className="flex justify-between items-center w-full">
                    <span>Inbox</span>
                    <span className="font-normal">4</span>
                  </SidebarLabel>
                </SidebarItem>
              </SidebarSection>
            </SidebarHeader>
            <SidebarBody>
              <SidebarSection>
                {menuItems
                  .filter((item) => !item.bottom)
                  .map((item: any, index) => (
                    <SidebarItem
                      key={index}
                      href={item.path}
                      onClick={item?.onClick}
                      current={
                        item.path === "/"
                          ? currentPath === "/"
                          : currentPath
                              ?.toLowerCase()
                              ?.startsWith(`${item.path}`)
                      }
                    >
                      <item.icon />
                      <SidebarLabel>{item.name}</SidebarLabel>
                    </SidebarItem>
                  ))}
              </SidebarSection>
              <SidebarSpacer />
              <SidebarSection>
                {menuItems
                  .filter((item) => item.bottom)
                  .map((item: any, index) => (
                    <SidebarItem
                      key={index}
                      href={item.path}
                      onClick={item?.onClick}
                    >
                      <item.icon />
                      <SidebarLabel>{item.name}</SidebarLabel>
                    </SidebarItem>
                  ))}
              </SidebarSection>
            </SidebarBody>
            <SidebarFooter className="max-lg:hidden">
              <Dropdown>
                <DropdownButton as={SidebarItem}>
                  {userResFetching ? (
                    <Skeleton className="h-2 w-full rounded-full" />
                  ) : (
                    <UserProfile user={userRes} />
                  )}
                  <ChevronUpIcon />
                </DropdownButton>
                <DropdownMenu className="min-w-64" anchor="top start">
                  <DropdownItem href="/settings">
                    <Cog8ToothIcon />
                    <SidebarLabel>Settings</SidebarLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem href="/privacy-policy">
                    <ShieldCheckIcon />
                    <SidebarLabel>Privacy policy</SidebarLabel>
                  </DropdownItem>
                  <DropdownItem href="/share-feedback">
                    <LightBulbIcon />
                    <SidebarLabel>Share feedback</SidebarLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem onClick={logout}>
                    <ArrowRightStartOnRectangleIcon />
                    <SidebarLabel>Sign out</SidebarLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </SidebarFooter>
          </Sidebar>
        }
      >
        <Outlet />
      </SidebarLayout>
    </Auth>
  );
};
