import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const documentsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "documentsApi",
    baseUrl: "/api/documents",
  }),
  reducerPath: "documentsApi",
  endpoints: (builder) => ({
    getDocuments: builder.query<any, { include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return queryParams;
      },
      keepUnusedDataFor: 0,
    }),
    getDocumentById: builder.query<any, { id?: string; include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      keepUnusedDataFor: 0,
    }),
    getDocumentFieldsById: builder.query<any, { id?: string }>({
      query: (args) => `/${args.id}/fields`,
      keepUnusedDataFor: 0,
    }),
    getDocumentHistoryByTemplateId: builder.query<
      any,
      { documentTemplateId?: string }
    >({
      query: (args) => `/${args.documentTemplateId}/history`,
      keepUnusedDataFor: 0,
    }),
    patchDocumentById: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateDocumentById: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    getFieldUses: builder.query<any, { documentId: string; fieldId: string }>({
      query: (args) => `${args.documentId}/fields/${args.fieldId}/uses`,
      keepUnusedDataFor: 0,
    }),
    addDocumentParticipants: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/participants`,
        method: "POST",
        body: data,
      }),
    }),
    removeDocumentParticipants: builder.mutation<
      any,
      { id: string; personId: string }
    >({
      query: ({ id, personId }) => ({
        url: `/${id}/participants/${personId}`,
        method: "DELETE",
      }),
    }),
    deleteDocument: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
    updateDocumentSignature: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `/${id}/signature`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentByIdQuery,
  useGetDocumentFieldsByIdQuery,
  useGetDocumentHistoryByTemplateIdQuery,
  usePatchDocumentByIdMutation,
  useUpdateDocumentByIdMutation,
  useGetFieldUsesQuery,
  useAddDocumentParticipantsMutation,
  useRemoveDocumentParticipantsMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentSignatureMutation,
} = documentsApi;
