import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ProviderNote = {
  id: string;
  note: string;
  date: string;
};

type Provider = {
  id: string;
  trusted: boolean;
  role: string;
  company: string;
  certifications: string[];
  bio: string;
  fullName: string;
  email: string;
  phone: string;
  deal: string;
  rating: number;
  notes: ProviderNote[];
};

type Providers = {
  providers: Provider[];
};

const initialState: Providers = {
  providers: [],
};

const trustedProvidersSettingsSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    addTrustedProvider: (state, action: PayloadAction<Provider>) => {
      state.providers.push(action.payload);
    },
    updateTrustedProvider: (
      state,
      action: PayloadAction<{ id: string; updatedProvider: Provider }>
    ) => {
      const { id, updatedProvider } = action.payload;
      const index = state.providers.findIndex((pro) => pro.id === id);
      if (index !== -1) {
        state.providers[index] = updatedProvider;
      }
    },
    removeTrustedProvider: (state, action: PayloadAction<string>) => {
      state.providers = state.providers.filter(
        (pro) => pro.id !== action.payload
      );
    },
  },
});

export const {
  addTrustedProvider,
  updateTrustedProvider,
  removeTrustedProvider,
} = trustedProvidersSettingsSlice.actions;
export default trustedProvidersSettingsSlice.reducer;
