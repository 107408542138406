import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const personsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "personsApi",
    baseUrl: "/api/persons",
  }),
  reducerPath: "personsApi",
  endpoints: (builder) => ({
    getPersons: builder.query<any, { include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `${queryParams}`;
      },
    }),
    getPersonById: builder.query({
      query: (id) => id,
      keepUnusedDataFor: 0,
    }),
    createRelationship: builder.mutation<any, { name: string; email: string }>({
      query: (relationship) => ({
        url: "/",
        method: "POST",
        body: relationship,
      }),
    }),
  }),
});

export const {
  useGetPersonsQuery,
  useLazyGetPersonsQuery,
  useLazyGetPersonByIdQuery,
  useGetPersonByIdQuery,
  useCreateRelationshipMutation,
} = personsApi;
